'use client';

import config from '@data/config.json';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import { filterCountries, getMarketByCountryCode } from '@lib/utils/clUtils';
import { COUNTRY_CODE_KEY, MARKET_ID_KEY } from '@shared/constants';
import { localStorageGetItem, localStorageSetItem } from '@utils/local-storage';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';

//PROJECT CONST
const endpoint = process.env.CL_ENDPOINT;
const clientId = process.env.CL_CLIENT_ID;
const projectName = config.projectName;
const defaultLocale = config.configuration.locale.code;
const defaultCountry = config.configuration.country;
const market = getMarketByCountryCode(defaultCountry.zoneCode);
const locales = config.availableLocales.locales;
const countries = filterCountries(defaultCountry.zoneCode);
const hasMultipleCountries = countries.length > 1 ? true : false;
const defaultMarketId = market.market_id;
//STATE REDUCER

function reducer(state, action) {
  const { type, data } = action;
  switch (type) {
    case 'TOGGLE_COUNTRY_MODAL':
      return {
        ...state,
        countryModal: data,
      };
    case 'TOGGLE_AUTH_MODAL':
      return {
        ...state,
        authModal: data,
      };
    case 'TOGGLE_FEEDBACK_MODAL':
      return {
        ...state,
        feedbackModal: data,
      };
    case 'TOGGLE_FEEDBACK_MODAL_USER':
      return {
        ...state,
        feedbackModalUser: data,
      };
    case 'TOGGLE_FEEDBACK_MODAL_QUANTITY':
      return {
        ...state,
        feedbackModalQuantity: data,
      };
    case 'TOGGLE_PAYMENT_ERROR_MODAL':
      return {
        ...state,
        paymentErrorModal: data,
      };
    case 'SET_NUM_ITEMS':
      return {
        ...state,
        numItems: data ? data : 0,
      };
    case 'SET_ORDER':
      return {
        ...state,
        order: data,
      };
    case 'SET_LOCALE':
      return {
        ...state,
        locale: data,
      };
    case 'SET_BASE_URL':
      return {
        ...state,
        baseUrl: data,
      };
    case 'INIT_MARKET':
      return {
        ...state,
        marketId: data.marketId,
        countryCode: data.countryCode,
      };
    case 'SHOW_TOAST':
      return {
        ...state,
        toast: {
          show: true,
          message: data.message,
          error: data.error,
          autoClose: data.autoClose,
          action: data.action,
        },
      };
    case 'HIDE_TOAST':
      return {
        ...state,
        toast: { show: false, message: '' },
      };
    default:
      return state;
  }
}

const initialState = {
  locales,
  projectName,
  defaultMarketId,
  hasMultipleCountries,
  locale: defaultLocale,
  countryCode: defaultCountry.zoneCode,
  marketId: hasMultipleCountries ? null : defaultMarketId,
  cartId: null,
  countryModal: false,
  authModal: false,
  feedbackModal: false,
  numItems: 0,
  order: null,
  baseUrl: null,
  toast: { show: false, message: '' },
};

const DataContext = createContext();

function DataProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [client, setClient] = useState(null);
  const { defaultMarketId, marketId, countryCode } = state;

  // Initialize Commerce Layer client
  useEffect(() => {
    if (marketId && countryCode) {
      log('MARKET CHANGE -> INIT CL', marketId, countryCode);
      const initClient = async () => {
        const params = { marketId, countryCode, endpoint, clientId };
        try {
          const cli = new AppCommerceLayerClient(params);
          await cli.init();
          setClient(cli);
        } catch (error) {
          console.error('Failed to connect to Commerce Layer', error);
          setClient(null);
        }
      };
      initClient();
    }
  }, [marketId, countryCode]);

  function log(...args) {
    //console.log(...args);
  }

  //FIRST RUN
  useEffect(() => {
    if (!hasMultipleCountries) {
      // console.log('SET DEFAULT MARKET');
      localStorageSetItem(`${projectName}${MARKET_ID_KEY}`, defaultMarketId);
      localStorageSetItem(`${projectName}${COUNTRY_CODE_KEY}`, defaultCountry.zoneCode);
    }
    let marketId = localStorageGetItem(`${projectName}${MARKET_ID_KEY}`) || defaultMarketId;
    let countryCode =
      localStorageGetItem(`${projectName}${COUNTRY_CODE_KEY}`) || defaultCountry.zoneCode;
    //check localstorage validity
    if (getMarketByCountryCode(countryCode)?.market_id !== marketId) {
      //fallback to default value
      marketId = defaultMarketId;
      countryCode = defaultCountry.zoneCode;

      localStorageSetItem(`${projectName}${MARKET_ID_KEY}`, marketId);
      localStorageSetItem(`${projectName}${COUNTRY_CODE_KEY}`, countryCode);
    }
    const data = { marketId, countryCode };
    log('INIT_MARKET  ', data);
    dispatch({ type: 'INIT_MARKET', data });
  }, [defaultMarketId]);

  return (
    <DataContext.Provider value={{ state, dispatch, client, setClient }}>
      {props.children}
    </DataContext.Provider>
  );
}

export function useAppContext() {
  return useContext(DataContext);
}

export default DataProvider;
