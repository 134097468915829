import { Locales } from '@model/common';
import de from './labels/de.json';
import en from './labels/en.json';
import fr from './labels/fr.json';
import it from './labels/it.json';

export type Labels = keyof typeof en;
const allLabels: Record<Locales, Record<Labels, string>> = { en, it, de, fr };

function t(locale: Locales, label: Labels) {
  let text = locale + ' - ' + label;
  if (allLabels[locale] && allLabels[locale][label]) {
    text = allLabels[locale][label];
  }
  return text;
}

export function tt(locale: Locales, label: Labels, params: Record<string, string>) {
  let text = t(locale, label);
  for (const [key, value] of Object.entries(params)) {
    text = text.replace(`#${key}`, value);
  }
  return text;
}

export default t;
